import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { QUERY, PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import PropertyAddress from "@/view/pages/customer/create-or-update/Property-Address.vue";
import PropertyMixin from "@/core/plugins/property-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";
export default {
  mixins: [CommonMixin, PropertyMixin],
  name: "property-create",
  title: "Create Property",
  data: function data() {
    return {
      customer: 0,
      customerDialog: 0,
      propertyCreated: false,
      property: 0,
      propertyArr: {},
      customerObject: {},
      propertyAddress: {},
      billingAddress: {},
      contactDetail: {},
      contactPerson: [],
      notification: {},
      options: {},
      tabs: [{
        title: "Contact Details",
        icon: "mdi-phone",
        key: "details"
      }, {
        title: "Contact Persons",
        icon: "mdi-account-multiple",
        key: "persons"
      }, {
        title: "Property Address",
        icon: "mdi-home-map-marker",
        key: "property"
      }, {
        title: "Company Address",
        icon: "mdi-credit-card",
        key: "billing"
      }, {
        title: "Notification & Remarks",
        icon: "mdi-bell-ring",
        key: "notification"
      }],
      mapCenter: {
        lat: 1.330906,
        lng: 103.891671,
        custom: false
      },
      propertyTab: null
    };
  },
  components: {
    PropertyAddress: PropertyAddress,
    CustomerDialog: CustomerDialog,
    CreateUpdateTemplate: CreateUpdateTemplate
  },
  watch: {
    formValid: function formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
    property: function property(param) {
      if (param > 0) {
        this.getPropertyDetail();
      }
    }
  },
  methods: {
    selectCustomer: function selectCustomer(customer) {
      if (customer > 0) {
        this.hasChanges = false;
        this.$router.replace(this.getDefaultRoute("property.create", {
          query: {
            customer: customer
          }
        })).then(function () {
          window.location.reload(true);
        });
      }
    },
    updatePosition: function updatePosition(param) {
      if (param) {
        this.mapCenter.custom = true;
        this.mapCenter.lat = param.lat;
        this.mapCenter.lng = param.long;
      }
    },
    positionChanged: function positionChanged(param) {
      if (param.latLng) {
        this.mapCenter.custom = false;
        this.mapCenter.lat = param.latLng.lat();
        this.mapCenter.lng = param.latLng.lng();
      }
    },
    updatePropertyAddress: function updatePropertyAddress(param) {
      this.propertyAddress = param;
    },
    getOptions: function getOptions() {
      var _this = this;

      var data = {
        customer: _this.customer
      };

      _this.$store.dispatch(QUERY, {
        url: "property/options",
        data: data
      }).then(function (_ref) {
        var data = _ref.data;
        _this.customerObject = data.customer;
        _this.options = data.options;
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    onSubmit: function onSubmit() {
      var _this = this;

      console.log({
        propertyAddress: this.propertyAddress
      });

      if (!_this.$refs.propertyForm.validate()) {
        return false;
      }

      if (_this.propertyAddress.is_tenant) {
        if (!_this.propertyAddress.contact_person) {
          ErrorEventBus.$emit("update:error", "Please enter tenant details.");
          return false;
        }
      } else {
        if (!_this.propertyAddress.contact_person) {
          ErrorEventBus.$emit("update:error", "Please select contact person.");
          return false;
        }
      }

      var address = [];

      if (_this.lodash.isEmpty(_this.propertyAddress) === false) {
        address.push(_this.propertyAddress);
      }

      _this.formLoading = true;

      _this.$store.dispatch(CLEAR_ERROR, {});

      if (_this.lodash.isEmpty(address) === false) {
        _this.$store.dispatch(PUT, {
          url: "address/" + _this.customer,
          data: {
            address: address
          }
        }).then(function () {
          _this.propertyCreated = true;

          _this.$nextTick(function () {
            _this.$router.push(_this.getDefaultRoute("property", {
              query: {
                status: "active"
              }
            }));
          });
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.formLoading = false;
        });
      }
    },
    getPropertyDetail: function getPropertyDetail() {
      var _this = this;

      _this.getProperty().then(function (response) {
        _this.propertyArr = response;
        _this.customer = response.customer ? response.customer.id : 0;
        _this.mapCenter = {
          lat: parseFloat(response.latitude),
          lng: parseFloat(response.longitude),
          custom: true
        };

        _this.$store.dispatch(SET_BREADCRUMB, [{
          title: "Property",
          route: "property"
        }, {
          title: "Update"
        }, {
          title: response.barcode
        }]);
      }).catch(function (error) {
        _this.logError(error);

        _this.$router.go(-1);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.getOptions();

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Property",
      route: "property"
    }, {
      title: "Create"
    }]);
  },
  created: function created() {
    var _this = this;

    _this.property = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.customer = _this.lodash.toSafeInteger(_this.$route.query.customer);

    if (_this.customer <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getAction: function getAction() {
      if (this.property) {
        return "Update";
      }

      return "Create";
    },
    getPropertyTitle: function getPropertyTitle() {
      var _this = this;

      if (_this.propertyArr && _this.propertyArr.barcode) {
        return " • " + _this.propertyArr.barcode;
      }

      return "";
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    var _this = this;

    if (_this.propertyCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  }
};